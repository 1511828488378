export const cookieService = {
  get: name => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
    return null;
  },

  set: (name, value, expiresDays) => {
    let expiresString = '';
    if (expiresDays !== undefined) {
      const now = new Date();
      const dueDate = new Date(now);
      dueDate.setDate(now.getDate() + expiresDays);
      expiresString = ` expires=${dueDate.toUTCString()}`;
    }
    document.cookie = `${name}=${value}; Path=/;${expiresString}`;
  },

  remove: name => {
    document.cookie = `${name}=; Path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
};
